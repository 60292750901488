import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";

import { EnvelopesOverview, IsGeometry, OnboardingTooltip, Loading, SubjectsPie, PrepareSupplies } from "components";
import { PIE_CHART_WIDTH } from "components/SubjectsPie/SubjectsPie";
import type { ISubjectDataForPie } from "components/SubjectsPie/SubjectsPie";

import { useAppSelector, useAppDispatch } from "store/hooks";
import { selectPreferredSubject, selectSessionCount, getUserEmail } from "store/slices/user";
import { clearSessionId, createEmptyResults, setSelectedTime } from "store/slices/exercise";
import { resetAllTimers } from "store/slices/timer";

import { formatDate } from "utils/calendarDateFormatting";
import { getFirebaseImageURL } from "utils/getFirebaseImageURL";
import { parseDataForPieChart } from "utils/parseDataForPieChart";
import { processApiError } from "utils/processApiError";
import { getSessionFromStorage, saveSessionToStorage } from "utils/sessionStorageHandler";

import {
  useLazyGetTimetableQuery,
  useSessionCreateMutation,
  useSessionStartMutation,
  useSessionCloseMutation,
} from "api/generated";
import type { SessionCreateMutation } from "api/generated";

import { ReactComponent as IconPlus } from "images/icons/plus.svg";
import { ReactComponent as IconMinus } from "images/icons/minus.svg";

import { tooltipTexts } from "./TooltipTexts";
import TagManager from "react-gtm-module";

const setHighlightClass = (element: HTMLElement) => {
  element.classList.add("highlight-tooltip");
};

const resetHighlightClass = (element: HTMLElement) => {
  element.classList.remove("highlight-tooltip");
};

interface IPageData {
  subjects: null | string[];
  pieChart: ISubjectDataForPie[];
  envelopes: Record<"red" | "orange" | "green", number> | null;
  easySubjectsCount: number;
}

const TIME_STEP = 5;
const TIME_MIN = 30;
const TIME_MAX = 90;

const Intro = () => {
  const navigate = useNavigate();

  const { t } = useTranslation(["exercise"]);
  const dispatch = useAppDispatch();
  const sessionsCount = useAppSelector(selectSessionCount);
  const preferredSubject = useAppSelector(selectPreferredSubject);
  const userEmail = useAppSelector(getUserEmail);

  const [createSession, { error: apiCreateError }] = useSessionCreateMutation();
  const [startSession, { isLoading: startingSession, error: apiStartError }] = useSessionStartMutation();
  const [closeSession, { error: apiCloseError }] = useSessionCloseMutation();
  const [getCalendarData, { isError: isTimetableError, error: apiError }] = useLazyGetTimetableQuery();

  const [loadingState, setLoadingState] = useState({ isUpdate: true, isInitialLoad: true });
  const [selectedTime, selectTime] = useState<number | undefined>(undefined);
  const [geometryImages, setGeometryImages] = useState<string[]>([]);
  const [apiData, setApiData] = useState<IPageData>({
    subjects: null,
    pieChart: [],
    envelopes: null,
    easySubjectsCount: 0,
  });
  const [shownTooltipIndex, setShownTooltipIndex] = useState<number | null>(null);

  const loadingData = useRef(false);
  const isSessionClosed = useRef(false);
  const highlightedElement = useRef<HTMLElement | null>(null);
  const shownTooltips = useRef<number[]>([]);
  const processingTooltip = useRef<boolean>(false);

  const today = new Date();

  useEffect(() => {
    if (loadingState.isInitialLoad) return;

    if (typeof sessionsCount !== "number" || sessionsCount === 0) {
      showNextTooltip();
    }
  }, [loadingState.isInitialLoad, sessionsCount]);

  useEffect(() => {
    const sessionData = getSessionFromStorage(userEmail);
    const sessionId = sessionData?.sessionId;

    if (typeof sessionId === "string" && sessionData !== null) {
      navigate(`/cviceni/${sessionId}`);
    } else {
      dispatch(clearSessionId());
      getCalendarData({
        dateFrom: formatDate(today),
        dateTo: formatDate(today),
      })
        .then(response => {
          if (
            "data" in response &&
            response.data?.timetable !== undefined &&
            response.data.timetable.timetable.length > 0
          ) {
            const today = formatDate(new Date());
            for (const week of response.data.timetable.timetable) {
              if (week.dateStart > today || week.dateEnd < today) {
                continue;
              }

              for (const day of week.plan) {
                if (day.date === today && typeof day.duration === "number") {
                  selectTime(Math.max(TIME_MIN, day.duration));

                  return;
                }
              }
            }
          }

          selectTime(TIME_MIN);
        })
        .catch(console.error);
    }
  }, []);

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    if (apiCreateError === undefined) return;

    processApiError(apiCreateError);
  }, [apiCreateError]);

  useEffect(() => {
    if (apiStartError === undefined) return;

    processApiError(apiStartError);
  }, [apiStartError]);

  useEffect(() => {
    if (
      typeof apiCloseError === "object" &&
      apiCloseError !== null &&
      "message" in apiCloseError &&
      typeof apiCloseError.message === "string" &&
      (apiCloseError.message.toLocaleLowerCase().includes("no session to be closed") ||
        apiCloseError.message.toLocaleLowerCase().includes("session created but not started"))
    )
      return;

    processApiError(apiCloseError);
  }, [apiCloseError]);

  const sortSubjects = (subjects: string[]) => {
    subjects.sort();

    if (typeof preferredSubject === "string" && subjects.includes(preferredSubject)) {
      /* Moves preferred subject to last position */
      subjects.push(subjects.splice(subjects.indexOf(preferredSubject), 1)[0]);
    }

    return subjects;
  };

  const countEnvelopes = (data: SessionCreateMutation) => {
    const result = {
      red: 0,
      orange: 0,
      green: 0,
    };

    for (const assignment of data.sessionCreate.userAssignments) {
      switch (assignment.envelope) {
        case "red":
          result.red++;
          break;
        case "yellow":
          result.orange++;
          break;
        case "green":
          result.green++;
          break;
        default:
      }
    }

    return result;
  };

  useEffect(() => {
    if (typeof selectedTime !== "number") return;
    loadingData.current = true;

    setGeometryImages([]);
    setLoadingState(state => {
      return {
        isUpdate: true,
        isInitialLoad: state.isInitialLoad,
      };
    });

    if (!isSessionClosed.current) {
      closeSession({ mood: 0, moodText: "", assignmentsClose: [] })
        .catch(console.error)
        .finally(() => {
          isSessionClosed.current = true;
          createNewSession(selectedTime);
        });
    } else {
      createNewSession(selectedTime);
    }
  }, [selectedTime]);

  const createNewSession = (time: number) => {
    createSession({ sessionLength: time * 60 })
      .then(data => {
        if ("data" in data) {
          for (const assignment of data.data.sessionCreate.userAssignments) {
            const imagePath = assignment.assignment?.assignment?.printingAttachment;

            if (typeof imagePath === "string") {
              getFirebaseImageURL(imagePath)
                .then(url => {
                  if (url === undefined) return;
                  setGeometryImages(images => {
                    const copy = [...images];
                    copy.push(url);
                    return copy;
                  });
                })
                .catch(() => {});
            }
          }
          const parsedData = parseDataForPieChart(data.data);
          const subjects = sortSubjects(parsedData.map(data => data.name));
          const envelopes = countEnvelopes(data.data);

          const easySubjects = new Set<string>();
          for (const easyAssignment of data.data.sessionCreate.userAssignments.slice(0, 2)) {
            const subjectName = easyAssignment.subject?.appName;
            if (typeof subjectName !== "string") continue;

            easySubjects.add(subjectName);
          }

          setApiData({ subjects, pieChart: parsedData, envelopes, easySubjectsCount: easySubjects.size });
        } else {
          setApiData({ subjects: [], pieChart: [], envelopes: null, easySubjectsCount: 0 });
        }
      })
      .catch(console.error)
      .finally(() => {
        loadingData.current = false;
        setLoadingState({
          isUpdate: false,
          isInitialLoad: false,
        });
      });
  };

  const increaseTime = () => {
    selectTime(prev => {
      if (typeof prev !== "number") return prev;

      return prev + TIME_STEP;
    });
  };

  const decreaseTime = () => {
    selectTime(prev => {
      if (typeof prev === "number" && TIME_MIN + TIME_STEP <= prev) {
        return prev - TIME_STEP;
      }

      return prev;
    });
  };

  const startExercise = (subject: string | null) => {
    if (loadingData.current) return;
    loadingData.current = true;

    TagManager.dataLayer({
      dataLayer: {
        event: "exercise-started",
        subject,
        duration: selectedTime,
        questionCount: apiData.pieChart.reduce((accumulator, subjectData) => accumulator + subjectData.value, 0),
      },
    });

    dispatch(resetAllTimers());
    dispatch(setSelectedTime(selectedTime));
    startSession()
      .then(response => {
        if ("data" in response) {
          const copy = [...response.data.sessionStart.userAssignments];

          if (subject !== null) {
            const startingAssignmentIndex = copy.findIndex(assignment => assignment.subject?.appName === subject);
            if (startingAssignmentIndex === 1) {
              copy.unshift(copy.splice(startingAssignmentIndex, 1)[0]);
            }
          }

          saveSessionToStorage(
            {
              sessionId: response.data.sessionStart.id,
              assignmentsData: copy,
              assignmentsResults: createEmptyResults(copy),
              spentTime: 0,
              newEnvelopes: {},
              selectedTime,
            },
            userEmail,
          );

          navigate(`/cviceni/${response.data.sessionStart.id}`);
        }
      })
      .catch(console.error)
      .finally(() => {
        loadingData.current = false;
      });
  };

  const showNextTooltip = () => {
    if (
      processingTooltip.current ||
      highlightedElement.current !== null ||
      shownTooltips.current.length >= tooltipTexts.length
    )
      return;

    processingTooltip.current = true;

    for (let tooltipIndex = 0; tooltipIndex < tooltipTexts.length; tooltipIndex++) {
      if (shownTooltips.current.includes(tooltipIndex)) continue;

      const elementId = tooltipTexts[tooltipIndex]?.elementId;
      highlightedElement.current =
        elementId !== null && typeof elementId === "string" ? document.getElementById(elementId) : null;

      if (highlightedElement.current !== null && highlightedElement.current !== undefined) {
        shownTooltips.current.push(tooltipIndex);
        setHighlightClass(highlightedElement.current);
        setShownTooltipIndex(tooltipIndex);

        processingTooltip.current = false;

        return;
      }
    }

    processingTooltip.current = false;
  };

  const onTooltipClose = () => {
    if (highlightedElement.current !== null && highlightedElement.current !== undefined) {
      resetHighlightClass(highlightedElement.current);
    }

    highlightedElement.current = null;
    setShownTooltipIndex(null);
    showNextTooltip();
  };

  return (
    <div>
      <main className={"h-100 overflow-hidden d-flex flex-column justify-content-between justify-content-sm-start"}>
        <div className="page" style={{ minWidth: PIE_CHART_WIDTH }}>
          {loadingState.isInitialLoad ? (
            <div className={"fullpage bg-primary-subtle"}>
              <Loading />
            </div>
          ) : isTimetableError ? (
            <div className={"fullpage bg-primary-subtle"}>
              {/* TODO: translate :D */}
              Během načítání dat došlo k chybě
            </div>
          ) : (
            <>
              <div className="page__header">
                <LinkContainer to="/">
                  <Button variant={"close"} />
                </LinkContainer>
              </div>

              <div className="page__content" style={{ width: "100%" }}>
                <div id="timer">
                  <p className="text-center mb-12px">
                    <strong>{t("label.youGotMoreTime", { ns: "exercise" })}</strong>
                  </p>

                  <div className={`increment-group ${apiData.pieChart.length > 0 ? "" : "mb-32px"}`}>
                    <Button
                      variant={"primary"}
                      className={"btn-circular"}
                      onClick={decreaseTime}
                      disabled={
                        loadingState.isUpdate ||
                        typeof selectedTime !== "number" ||
                        selectedTime <= TIME_MIN ||
                        startingSession ||
                        apiData.subjects === null
                      }
                    >
                      <IconMinus />
                    </Button>
                    <p className={"h2 mb-0"}>
                      {selectedTime} {t("minuteShort", { ns: "common" })}
                    </p>
                    <Button
                      variant={"primary"}
                      className={"btn-circular"}
                      onClick={increaseTime}
                      disabled={
                        loadingState.isUpdate ||
                        typeof selectedTime !== "number" ||
                        startingSession ||
                        apiData.subjects === null ||
                        apiData.subjects.length === 0 ||
                        selectedTime >= TIME_MAX
                      }
                    >
                      <IconPlus />
                    </Button>
                  </div>
                </div>

                {apiData.pieChart.length > 0 ? (
                  <SubjectsPie data={apiData.pieChart} />
                ) : (
                  <div
                    style={{
                      height: 174,
                    }}
                  />
                )}

                {apiData.envelopes === null ? null : (
                  <div className="mb-32px">
                    <EnvelopesOverview envelopesCount={apiData.envelopes} />{" "}
                  </div>
                )}

                <PrepareSupplies />

                {geometryImages.length > 0 ? <IsGeometry imagesUrl={geometryImages} /> : null}
              </div>

              <div className="page__footer">
                {apiData.subjects === null ? null : (
                  <>
                    <p className="text-center mb-12px">
                      {apiData.easySubjectsCount === 0 ? (
                        <>
                          <strong>{t("header.noAssignments")}</strong>
                        </>
                      ) : apiData.easySubjectsCount === 1 ? null : (
                        <strong>{t("header.startWith")}</strong>
                      )}
                    </p>

                    {apiData.easySubjectsCount === 0 ? null : apiData.easySubjectsCount === 1 ? (
                      <Button
                        type={"submit"}
                        className={"w-100 text-uppercase"}
                        onClick={() => {
                          startExercise(null);
                        }}
                        disabled={loadingState.isInitialLoad || loadingState.isUpdate || startingSession}
                      >
                        {t("btn.start", { ns: "exercise" })}
                      </Button>
                    ) : (
                      apiData.subjects.map((subject, index) => {
                        return (
                          <Button
                            key={subject}
                            type={"submit"}
                            className={"w-100 text-uppercase"}
                            onClick={() => {
                              startExercise(subject);
                            }}
                            disabled={loadingState.isInitialLoad || loadingState.isUpdate || startingSession}
                            {...(index === 0 ? {} : { variant: "outline-primary" })}
                          >
                            {subject}
                          </Button>
                        );
                      })
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>

        <OnboardingTooltip
          key={shownTooltipIndex}
          data={shownTooltipIndex === null ? null : tooltipTexts[shownTooltipIndex]}
          onConfirm={onTooltipClose}
          highlightedElement={highlightedElement.current ?? null}
        />
      </main>
    </div>
  );
};

export default Intro;
